#wrapper {
  background-image: url('../images/loading-page-desktop.jpg');
  background-size: cover;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 999;
  transition: 0.2s;
}
@media only screen and (max-width: 768px) {
  #wrapper {
    background-color: #000;
  }
  ::-webkit-scrollbar{
    display: none;
  }
}
#loading-logo-img {
  margin: 0;
  position: absolute;
  top: 50%;
  width: 200px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.project-img:hover{
  opacity: 0.5 !important;
}
.sort-text{
  text-align: justify;
  text-justify: inter-word;
}


.image-slide {
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial-group > .row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
.testimonial-group > .row > .col-lg-2 {
  display: inline-block;
}
.testimonial-group > .row > .col-xs-6 {
  display: inline-block;
}
.testimonial-group > .row > .col-sm-6 {
  display: inline-block;
}

/* width */
::-webkit-scrollbar {
  height: 4px;              /* height of horizontal scrollbar ← You're missing this */
  width: 10px;               /* width of vertical scrollbar */
  
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(196, 196, 196); 
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(196, 196, 196); 
}

.socialiframe{
  width: 100%;
  height: 400px;
}



.fullVideo{
  width: 100%;
  height: 400px;
}


.container.gallery-container {
  background-color: #fff;
  color: #35373a;
  /* min-height: 100vh; */
}

.gallery-container h1 {
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}

.gallery-container p.page-description {
  text-align: center;
  margin: 25px auto;
  font-size: 18px;
  color: #999;
}

.tz-gallery {
  padding: 40px;
}

/* Override bootstrap column paddings */
.tz-gallery .row > div {
  padding: 2px;
}

.tz-gallery .lightbox img {
  width: 100%;
  border-radius: 0;
  position: relative;
}




.tz-gallery .lightbox:hover:after,
.tz-gallery .lightbox:hover:before {
  opacity: 1;
}

.baguetteBox-button {
  background-color: transparent !important;
}

@media(max-width: 768px) {
  body {
      padding: 0;
  }
}
