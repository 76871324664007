.alert{
    border: 0px !important;
    color: white;
}
.alert-success{
     background-color: #1ec465 !important;
}
.alert-danger{
     background-color: #bf0013 !important;
}
.alert-info{
     background-color: #0077b2 !important;
}
.alert-warning{
     background-color: #ffaa00 !important;
}